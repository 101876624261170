import { render, staticRenderFns } from "./EditBL.vue?vue&type=template&id=4f4c8164&scoped=true&"
import script from "./EditBL.vue?vue&type=script&lang=js&"
export * from "./EditBL.vue?vue&type=script&lang=js&"
import style0 from "./EditBL.vue?vue&type=style&index=0&id=4f4c8164&scoped=true&lang=css&"
import style1 from "./EditBL.vue?vue&type=style&index=1&id=4f4c8164&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f4c8164",
  null
  
)

export default component.exports